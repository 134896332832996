import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Markdown from 'react-markdown';
import LayoutContainer from '../containers/layout';
import PageBackgroundImageComponent from '../components/page-background-image';
import PageContentComponent from '../components/page-content';
import PageTitleComponent from '../components/page-title';
import DividerComponent from '../components/divider';
import ButtonSeeAlsoComponent from '../components/button-see-also';
import ContactUsContainer from '../containers/contact-us';
import PackagesContainer from '../containers/packages';

const ManagersPage = () => {
  const data = useStaticQuery(query);

  return (
    <LayoutContainer title={data.strapiManagers.title} description={data.strapiManagers.description}>
      <PageTitleComponent showBackground={true}>
        <div className="text-4xl lg:text-5xl text-black">{data.strapiManagers.title}</div>
      </PageTitleComponent>

      <PageBackgroundImageComponent image={data.strapiManagers.backgroundImage.localFile.childImageSharp.gatsbyImageData} />

      <PageContentComponent showBackground={true}>
        <section className="mb-12">
          <div className="flex">
            <div className="w-full lg:w-3/5 lg:pr-20">
              <div className="w-full text-4xl text-blue-600 mb-12">{data.strapiManagers.subtitle}</div>
              <Markdown className="no-widows w-full markdown">{data.strapiManagers.description}</Markdown>
            </div>
            <div className="relative hidden lg:block w-2/5 pl-20 pt-4">
              <div className="absolute -top-16 left-0 w-0.5 h-48 bg-gray-200"></div>
              <div className="henderson-font text-black text-xs font-semibold mb-4">ZOBACZ TEŻ:</div>
              <Link to="/angielski-dla-firm" className="block">
                <ButtonSeeAlsoComponent title="Pracownicy" />
              </Link>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <PackagesContainer packages={data.strapiManagers.packages} />
        </section>

        <section className="mb-12">
          <DividerComponent />
        </section>

        <section>
          <ContactUsContainer />
        </section>
      </PageContentComponent>
    </LayoutContainer>
  );
};

const query = graphql`
  query {
    strapiManagers {
      title
      subtitle
      description
      backgroundImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO, quality: 92)
          }
        }
      }
      packages {
        hours
        subtitle
        description
      }
    }
  }
`;

export default ManagersPage;
